$( document ).ready(function() {


 
    //slider mainPageSlider
    $('.mainPageSlider').owlCarousel({
      items:1,
        lazyLoad:true,
        autoplayHoverPause:true,
        loop:true,
        mouseDrag : false,
        margin:10,
        autoplay:true,
        autoplayTimeout:4000,
        smartSpeed:1250,
        nav:false,
        dots:true,
        //navText: [$('.owl-left'),$('.owl-right')]
  });
  //end slider mainPageSlider



    //slider O Nas
    $('.AboutUs__Slider').owlCarousel({
      margin:10,
      responsiveClass:true,
      nav:false,
      dots:true,
      loop:true,
      autoplay: true,
      responsive:{
          0:{
              items:1
          },
          600:{
              items:1
          },
          1000:{
              items:1
          }
      }
    });

    //slider galerii
    $('.Gallery__Slider').owlCarousel({
      margin:10,
      responsiveClass:true,
      nav:false,
      dots:true,
      loop:true,
      autoplay: true,
      responsive:{
          0:{
              items:3
          },
          600:{
              items:4
          },
          1000:{
              items:6
          }
      }
    });

    
    $('.buttonScrollDown').click(function() {
        $('html,body').animate({
            scrollTop: $('.About').offset().top},
            'slow');
      });

    
      $('#Home').click(function() {
        $('#menyAvPaa').prop("checked", false);
        $('html,body').animate({
            scrollTop: $('section.Home').offset().top - 20},
            'slow');
      });

    
      $('#About').click(function() {
        $('#menyAvPaa').prop("checked", false);
        $('html,body').animate({
            scrollTop: $('section.About').offset().top - 20},
            'slow');
      });

    
      $('#Products').click(function() {
        $('#menyAvPaa').prop("checked", false);
        $('html,body').animate({
            scrollTop: $('section.Products').offset().top - 20},
            'slow');
      });

    
      $('#MenuRestaurant').click(function() {
        $('#menyAvPaa').prop("checked", false);
        $('html,body').animate({
            scrollTop: $('section.Menu').offset().top - 20},
            'slow');
      });

    
      $('#Gallery').click(function() {
        $('#menyAvPaa').prop("checked", false);;
        $('html,body').animate({
            scrollTop: $('section.Gallery').offset().top - 20},
            'slow');
      });

    
      $('#Location').click(function() {
        $('#menyAvPaa').prop("checked", false);
        $('html,body').animate({
            scrollTop: $('section.Location').offset().top - 20},
            'slow');
      });

      $('#Contact').click(function() {
        $('#menyAvPaa').prop("checked", false);
        $('html,body').animate({
            scrollTop: $('footer.Contact').offset().top - 20},
            'slow');
      });
  
	
    
});
